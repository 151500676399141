import type { IndividualDataMinimalProduct } from '../../stores/individual.types'
import useIndividualProductData from './useIndividualProductData'

/**
 * Get product alias (for logged in users)
 * @param {ProductInterfaceExtended} product
 * @returns {ComputedRef<string | null>} Product alias
 */
function useProductAlias(product: IndividualDataMinimalProduct): ComputedRef<string | null> {
  const { getProductAlias } = useIndividualProductData()

  const individualProductAlias = computed(() => {
    if (!product) {
      return null
    }
    const individualQty = getProductAlias(product)
    return individualQty?.alias || null
  })

  return individualProductAlias
}

export default useProductAlias
